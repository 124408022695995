import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenBagsPage() {
  return (
    <CategoryWrapper
      seoTitle="Tasker"
      gender="dame"
      category="bags"
    />
  )
}

export default WomenBagsPage
